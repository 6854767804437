exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .stop-all_stop-all_15h2t {\n    width: 2rem;\n    height: 2rem;\n    padding: 0.375rem;\n    border-radius: 0.25rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    cursor: pointer;\n} .stop-all_stop-all_15h2t:hover {\n    background-color: hsla(323, 100%, 50%, 0.15);\n} .stop-all_stop-all_15h2t {\n    opacity: 0.5;\n} .stop-all_stop-all_15h2t.stop-all_is-active_PEgCP {\n    opacity: 1;\n}\n", ""]);

// exports
exports.locals = {
	"stop-all": "stop-all_stop-all_15h2t",
	"stopAll": "stop-all_stop-all_15h2t",
	"is-active": "stop-all_is-active_PEgCP",
	"isActive": "stop-all_is-active_PEgCP"
};